document.addEventListener('DOMContentLoaded', function () {
  const findstr = window.findstr || {};

  findstr.hooks.addFilter('urlManagementDisabled', 'disabledUrl', () => {
    return true;
  });

  findstr.hooks.addFilter(
    'findstrEmptyResultsMessageOnInit',
    'findstr-search-overlay',
    function (setEmptyAlertOnInit, group) {
      if ('search-autocomplete' === group || 'search' === group) {
        return false;
      }
      return setEmptyAlertOnInit;
    }
  );

  /*********** Search modal ************/
  const inputModal = document.getElementById('findstr_main_search_input');
  const resultsWrapperModal = document.querySelector(
    '.search-window__results-wrapper'
  );

  if (inputModal && resultsWrapperModal) {
    inputModal.addEventListener('input', () => {
      if (inputModal.value.length < 3) {
        resultsWrapperModal.style.display = 'none';
        inputModal.classList.remove('rounded-corner');
      } else {
        inputModal.classList.add('rounded-corner');
        resultsWrapperModal.style.display = 'block';
      }
    });

    // Empêcher la fermeture si on clique dans le wrapper
    resultsWrapperModal.addEventListener('mousedown', (e) => {
      e.preventDefault(); // empêche le input de perdre le focus immédiatement
    });

    inputModal.addEventListener('blur', () => {
      setTimeout(() => {
        resultsWrapperModal.style.display = 'none';
        inputModal.classList.remove('rounded-corner');
      }, 150);
    });
  }

  const searchInput = document.getElementById(
    'findstr_main_search_input_result'
  );
  const resultQuerySpan = document.getElementById('result-search-query');
  const noTermSpan = document.getElementById('result-search-no_term');
  const withTermSpan = document.getElementById('result-search-with_term');

  findstr.hooks.addAction('searchResults', 'findstrSearchResult', function () {
    if (withTermSpan && noTermSpan) {
      if (searchInput && searchInput.value !== '') {
        noTermSpan.style.display = 'none';
        withTermSpan.style.display = 'block';
      } else {
        withTermSpan.style.display = 'none';
        noTermSpan.style.display = 'inline';
      }
    }
    if (resultQuerySpan) {
      resultQuerySpan.textContent = ' ' + searchInput.value + ' ';
    }

    //result count
    const field = document.querySelector('.findstr-field-results-count');
    if (field) {
      const resulCount = field.querySelector('.findstr-results-count');

      if (resulCount) {
        // si on a pas de term recherche
        if (searchInput && searchInput.value === '') {
          resulCount.style.display = 'none';
        } else {
          resulCount.style.display = 'flex';
        }
      }
    }
  });

  // Synchronsier avec l'input de recherche
  if (searchInput && resultQuerySpan) {
    searchInput.addEventListener('input', (event) => {
      if (resultQuerySpan) {
        if (withTermSpan) {
          resultQuerySpan.style.display = 'inline';
        } else {
          resultQuerySpan.style.display = 'none';
        }
      }
      resultQuerySpan.textContent = ' ' + event.target.value + ' ';
    });
  }

  if (searchInput) {
    searchInput.addEventListener('input', () => {
      if (searchInput.value.trim() === '') {
        if (noTermSpan) {
          noTermSpan.style.display = 'inline';
          withTermSpan.style.display = 'none';
          resultQuerySpan.style.display = 'none';
        }
      } else {
        withTermSpan.style.display = 'block';
        resultQuerySpan.style.display = 'inline';
        noTermSpan.style.display = 'none';
      }
    });
  }
});
